<!--
 * @Descripttion: icons
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-10 23:07:46
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-15 00:18:19
-->
<template>
	<svg class="lius-icon" aria-hidden="true" @click="returnBackFn">
      <use :xlink:href="`#${icon}`" /> 
  </svg>
</template>
<script>
  import './../../styles/icon'
  export default {
    name: 'lius-icon', // 定义组件名
    props:{
      icon:{
        type: String,
        require:true // 设置必传
      }
    },
    mounted(){
      console.log('lius-icon:',this.icon)
    },
    methods: {
      returnBackFn(val){
        //input是默认双向绑定事件，select控件也可以用input给父组件传递数据
        this.$emit('click', this.iptValue)
      }
    }
  }
</script>
<style>
  .lius-icon {
    width: 1em; 
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
    cursor: pointer;
  }
</style>
