<template>
	<div class="weatherBox">
		<i class="el-icon-sunny" @click="weather_infor_isShow=true" v-if="!weather_infor_isShow"></i>
		<div class="weather" v-show="weather_infor_isShow">
			<i class="el-icon-arrow-up" @click="weather_infor_isShow = false"></i>
			<div class="getweather_infofail" v-if="weatherData.length == 0" v-show="weather_infor_isShow"> 未能获取到天气信息！ </div>
			<div class="we-min" v-else v-show="weather_infor_isShow">
				<div class="we-min-head">
					<p> <i class="el-icon-location-information"></i> {{weatherData.city }}</p>
					<p> {{weatherData.win }} - {{ weatherData.win_speed }}</p>
				</div>
				<p class="air_num">	<span> 空气指数： {{weatherData.air}}</span></p>
				<div class="we-min-info">
					<div class="info-left">
						<p> {{weatherData.tem}}<span class="we-infoleft-sp">℃</span></p>
					</div>
					<div class="info-right">
						<i class="wea_img" :class="item.icon" v-show="item.text == weatherData.wea_img" v-for="(item,index) in wea_img_icon" :key="index" ></i>
						<div class="">
							<p> {{ weatherData.tem_day }}℃ / {{ weatherData.tem_night }}℃</p>
							<p class="wea-min-wea">	<span> {{weatherData.wea }}</span>	</p>
						</div>
					</div>
				</div>
				<div class="weather-updateTime">
					<span> {{ weatherData.update_timeer }} &nbsp;<i class="el-icon-refresh"> </i></span>
				</div>
				<div class="we-min-msgtext">
					<!-- <p v-show="item.text == weatherData.wea_img" v-for="(item,index) in wea_img_icon" :key="index"> {{ item.msg }} </p> -->
					<p> {{ getWeatherMsgText() }} </p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import axios from 'axios'
	export default{
		data(){
			return{
				update_Time: 24,	//更新时差
				weatherData: [],
				weather_infor_isShow: true,
				wea_img_icon: [{
					text: 'xue',
					icon: 'el-icon-light-rain',
					msg: '温馨提示: 注意预防风雪！'
				},{
					text: 'yun',
					icon: 'el-icon-cloudy-and-sunny',
					msg: '温馨提示: 天气阴凉，正适合出门！'
				},{
					text: 'yu',
					icon: 'el-icon-heavy-rain',
					msg: '温馨提示: 出门记得带伞哦！'
				},{
					text: 'lei',
					icon: 'el-icon-lightning',
					msg: '温馨提示: 雷鸣电闪，注意安全！'
				},{
					text: 'shachen',
					icon: 'el-icon-heavy-rain',
					msg: '温馨提示: 外面沙尘暴，不建议出门！'
				},{
					text: 'wu',
					icon: 'el-icon-heavy-rain',
					msg: '温馨提示: 大雾弥漫，视线不好！'
				},{
					text: 'bingbao',
					icon: 'el-icon-light-rain',
					msg: '温馨提示: 冰雹来袭！'
				},{
					text: 'yin',
					icon: 'el-icon-cloudy-and-sunny',
					msg: '温馨提示: 天气阴凉，正适合出门！'
				},{
					text: 'qing',
					icon: 'el-icon-sunny',
					msg: '温馨提示:阳光明媚！'
				}]
			}
		},
		mounted() {
        
			if(this.isUpdateWeatherInfo() == 1)
				this.getWeatherData()
				// this.getWeatherData_Proginn()
			// document.querySelector('.weatherBox').className = 'weather_init';  
			// setTimeout(()=>{
			// 	document.querySelector('#hexahedronBox').className = 'moveIt';  
			// },300)
		},
		methods:{
			// 打开
			open(){
				document.querySelector('.weatherBox').classList.add('open');  
				document.querySelector('.weatherBox').classList.remove('close');  
			},
			// 关闭
			close(){
				document.querySelector('.weatherBox').classList.add('close'); 
				document.querySelector('.weatherBox').classList.remove('open');  
			},
			// 从https://tianqiapi.com官方接口获取天气信息
			getWeatherData(){
				axios.get('https://tianqiapi.com/free/day?appid=12752479&appsecret=7Lm9VXxH&unescape=1')
				.then(res =>{
					console.log(res)
					if(res.status != 200 || !res)
						return
					const weather_info = res.data
					const wtime = new Date().toLocaleDateString()
					weather_info['update_timeer'] = wtime + ' ' + weather_info.update_time
					weather_info['timeDate'] = new Date()
					console.log(weather_info)
					this.weatherData = weather_info
					localStorage.setItem('weather_info', JSON.stringify(weather_info))
				})
				.catch(error=>{
					console.log('weatherError:',error)
				})
			},
			// 程序员客栈的api获取天气信息 https://www.proginn.com/
			getWeatherData_Proginn(){
				axios.get('https://binstd.apistd.com/weather/query?key=2amqE4DqBQTPyfDzbfhMkkzq9')
				.then(res =>{
					console.log(res)
				})
			},
			// 判断是否更新天气信息
			isUpdateWeatherInfo(){
				const currentTime = new Date()
				const weather_info = JSON.parse(localStorage.getItem('weather_info') )
				if(!weather_info)
					return 1
				this.weatherData = weather_info
				
				const oldTime = new Date(weather_info.timeDate)
				const time_changeNum = (currentTime-oldTime)/1000
				const t_cHour = time_changeNum/3600
				
				console.log('weather_info:=====',weather_info)
				console.log(time_changeNum,t_cHour)
				if(t_cHour > this.update_Time )
					return 1
				else return -1
			},
			/** 提醒文字 */
			getWeatherMsgText(){
				let str = ''
				this.wea_img_icon.forEach(w=>{
					if(this.weatherData && this.weatherData.wea_img == w.text){
						str = w.msg
					}
				})
				if(this.weatherData.tem>=35){
					str += '高温天气，请做好防晒准备!'
				}
				// console.log(this.weatherData)
				return str
			},
			
		}
	}
</script>

<style lang="scss" scoped>
	.weatherBox{
		width: 96%;
		position: relative;
	}
	.weather{
		width: 100%;
		height: 210px;
		color: white;
		background-color: aqua;
		border-radius: 12px;
		padding: 10px;
		box-sizing: border-box;
		position: relative;
		opacity: 0.85;
		font-size: 18px;
		.air_num{
			margin-top: 0px;
		}
		.we-min-msgtext{
			margin-top: 15px;
			font-size: 14px;
		}
	}
	.we-min-head{
		display: flex;
		justify-content: space-between;
		padding-right: 20px;
	}
	
	.el-icon-location-information{
		color: green;
		font-size: 25px;
	}
	
	.wea_img{
		font-size: 60px;
		color: blue;
	}
	.we-min-info{
		display: flex;
		align-items: center;
		align-content: center;
		justify-content: space-around;
	}
	.info-left{
		font-size: 80px;
		color: white;
		.we-infoleft-sp{
			font-size: 30px;
			vertical-align: top;
		}
	}
	.info-right{
		display: flex;
		.wea-min-wea{
			font-size: 22px;
		}
	}
	// 获取天气信息失败文字样式
	.getweather_infofail{
		font-size: 22px;
		color: red;
		line-height: 150px;
	}
	.weather-updateTime{
		position: relative;
		span{
			position: absolute;
			top: -10px;
			left: 42%;
		}
	}
	.el-icon-arrow-up{
		font-size: 18px;
		color: orangered;
		font-weight: bolder;
		position: absolute;
		top: 0;
		right: 0;
		border: 1.3px solid greenyellow;
		border-radius: 5px 10px 5px 5px;
		// border-radius-right: 10px;
	}
	.el-icon-sunny{
		font-size: 20px;
		font-weight: bold;
		color: orange;
		position: absolute;
		top: 0;
		right: 5px;
	}
	.el-icon-sunny:hover{
		transform: scale3d(1.5,1.5,1.5);
		transition: all 0.5s;
	}

.open{
    animation: switch_open 2s linear 1;
    opacity: 1;
    display: block;
}

.close{
    animation: switch_out 1s linear 1;
    opacity: 0;
}

@keyframes switch_open {
    0%{
        opacity: 0;
        display: block;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes switch_out {
    0%{
        opacity: 0.9;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        display: none;
        transform: scale(0);
    }
}
</style>
