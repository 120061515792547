<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-09-09 22:53:12
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-10 23:06:17
-->
<template>
  <div class="homeMenuCpn1">
      <section class="menuItem" v-for="(m,index) in list" :key="index" @click="toPath(index+1)">
          <span> {{ m.name }} </span>
      </section>
  </div>
</template>

<script>
export default {
    name: 'homeMenuCpn1',
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    methods: {
        /** 点击了某个菜单 */
        toPath(num){
            this.$emit('toPath',num)
        }
    }
}
</script>

<style scope>
.homeMenuCpn1{
    min-width: 720px;
    max-height: 94vh;
    overflow-y: auto;
    position: fixed;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    z-index: 101;
    pointer-events: auto 
}
.menuItem{
    width: 200px;
    min-height: 250px;
    background-color: #ccc;
    color: red;
    margin: 20px 20px;
    margin-right: 10px;
    border-radius: 6px;
    box-shadow: 0 0 2px #f5f5f5;
    cursor: pointer;
    box-sizing: border-box;
    padding-top: 70px;
    font-size: 30px;
    line-height: 50px;
    font-weight: 900;
    letter-spacing: 2.5px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
}
.menuItem:hover{
    transform: scale(1.04);
    box-shadow: 0 0 5px red;
}

.menuItem:nth-child(1){
    background-image: url('@/assets/1.jpg');
}

.menuItem:nth-child(2) {
    background-image: url('@/assets/4.jpg');
}
.menuItem:nth-child(3) {
    background-image: url('@/assets/1.jpg');
}
.menuItem:nth-child(4) {
    background-image: url('@/assets/4.jpg');
}
.menuItem:nth-child(5) {
    background-image: url('@/assets/3.jpg');
}
.menuItem:nth-child(6) {
    background-image: url('@/assets/4.jpg');
}

  

</style>