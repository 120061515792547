<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-09-09 22:53:12
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-10 15:56:35
-->
<template>
  <div class="homeMenuCpn2">
       <section class="menuItem" v-for="(m,index) in list" :key="index" 
          @click="toPath(index+1)" @mouseenter="sFlag = index" @mouseleave="sFlag = -1">
            <div class="h-m-item-box">
                <span> {{ m.name }} </span>
            </div>
            <section class="inside-circle"></section>
            <section class="inside-circle1" @click.stop="()=>false"></section>
            <section class="selected-flag" v-show="sFlag == index"></section>
       </section>
  </div>
</template>

<script>
export default {
    name: 'homeMenuCpn2',
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return {
            // 激活菜单下标
            sFlag: -1,
        }
    },
    methods: {
        /** 点击了某个菜单 */
        toPath(num){
            this.$emit('toPath',num)
        }
    }
}
</script>

<style lang="scss" scope>
.homeMenuCpn2{
    width: 1200px;
    height: 1200px;
    overflow-y: auto;
    position: fixed;
    left: 50%;
    top: 40px;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    text-align: center;
    z-index: 101;
    box-sizing: border-box;
    padding-top: 0px;
    // background-color: aquamarine;
    border-radius: 50%;
}
.homeMenuCpn2 .menuItem{
    width: 256px;
    height: calc(50% + 6px );
    background-color: #ddd;
    color: red;
    // margin: 20px 1px;
    border-radius: 0;
    box-shadow: 0 0 2px #f5f5f5;
    cursor: pointer;
    box-sizing: border-box;
    // padding-top: 70px;
    font-size: 30px;
    line-height: 50px;
    font-weight: 900;
    letter-spacing: 2.5px;
    background-size: auto auto;
    background-repeat: no-repeat;
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 50%;
    margin: 0;
    transform: translateX(-50%);
    transform-origin: left bottom;
    border: none;
    // 将每一个都裁剪为三角形
    clip-path: polygon(0 0, 100% 0, 0 100%, 0 0);
    padding-right: 72px;
    padding-top: 100px;
    border-radius:  0 0 0 130px;
}


.homeMenuCpn2 .menuItem:hover{
    box-shadow: 0 0 15px red;
    font-size: 32px;
}
.homeMenuCpn2{
    .menuItem:nth-child(1){
        background-image: url('@/assets/1.jpg');
        transform: rotateZ(-60deg);
        transform-origin: left bottom;
        border-radius:  0 0 0 100px;
    }

    .menuItem:nth-child(2) {
        background-image: url('@/assets/4.jpg');
        transform: rotateZ(-40deg);
        transform-origin: left bottom;
    }
    .menuItem:nth-child(3) {
        background-image: url('@/assets/1.jpg');
        transform: rotateZ(-20deg);

    }
    .menuItem:nth-child(4) {
        background-image: url('@/assets/4.jpg');
        transform: rotateZ(0deg);
    }
    .menuItem:nth-child(5) {
        background-image: url('@/assets/3.jpg');
        transform: rotateZ(20deg);

    }
    .menuItem:nth-child(6) {
        background-image: url('@/assets/1.jpg');
        transform: rotateZ(40deg);
        // background: linear-gradient(45deg, deeppink, deeppink 50%, transparent 100%, transparent 50%);
    }
    .menuItem:nth-child(7) {
        transform: rotateZ(60deg);
        background-color: transparent;
    }

    .inside-circle{
        width: 500px;
        height: 500px;
        position: absolute;
        z-index: 9999;
        top: 100%;
        left: 0%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        border: 40px solid red;
        box-sizing: border-box;
        // clip-path: polygon(0 0, 100% 0,100% 0,100% 24%,52% 52%, 0 24%);
    }

    .inside-circle1{
        width: 200px;
        height: 200px;
        position: absolute;
        z-index: 9999;
        top: 100%;
        left: 0%;
        transform: translateX(-50%) translateY(-50%);
        border-radius: 50%;
        box-sizing: border-box;
        // background-color: red;
        border: 60px solid red;

    }

    .selected-flag{
        width: 80px;
        height: 80px;
        background-color: red;
        position: absolute;
        top: 240px;
        left: 20px;
        border-radius: 50%;
        background-image: url('@/assets/1.jpg');
        background-size: 100% 100%;
        border: 4px solid red;
    }
}


  

</style>