/*
 * @Descripttion: driver.js 新功能引导配置 
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-20 13:40:44
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-20 13:57:00
 */


import Driver from "driver.js" 
import "driver.js/dist/driver.min.css"
import Vue from 'vue'


Vue.prototype.$Driver1 = new Driver({
    classname:"scoped class",// 为你的指示模块添加类名 如果你要取消 某一个按钮（比如："上一步""下一步","完成"）的 这个类名还是要加上的
    animate:true,//在更改突出显示的元素时设置动画
    opacity: 0.75,//背景不透明度（0表示只有弹出窗口,没有覆盖）
    padding:10,//元素与边缘的距离
    allowclose:true,//单击覆盖是否应关闭
    overlay_click_next:false,//单击overlay click是否转到下一步
    donebtntext:"完成",//最后一个按钮上的文本
    closebtnext:'关闭',//此步骤的"关闭"按钮上的文本
    NextBtnText:'一步',//此步骤的下一步按钮文本
    prevbtnext:"上一步",//此步骤的上一个按钮文本
    show_buttons:false,//不在页脚中显示控件按钮
    keyboardcontrol:true,//允许通过键盘进行控制（转义为"关闭",箭头键为"移动"）。
    // scrollintoview options:,//我们尽可能使用'scrollintoview（）',如果需要,请在此处传递它的选项
    // onHighlightStarted:（element）=>{},//当要突出显示元素时调用
    // onHighlighted:（element）,//当element完全突出显示时调用
    // OnDeselected:（element）,//取消选择element后调用
    // onreset：（element）,//当要清除覆盖时调用
    onNext:(element)=>{},//在任何步骤上移动到下一步时调用
    onPrevious:(element)=>{},//在任何步骤上移动到下一步时调用
    
    })


export default new Driver({
        className: "scoped-class", // className to wrap driver.js popover
        animate: true, // Animate while changing highlighted element
        opacity: 0.75, // Background opacity (0 means only popovers and without overlay)
        padding: 10, // Distance of element from around the edges
        allowClose: true, // Whether clicking on overlay should close or not
        overlayClickNext: false, // Should it move to next step on overlay click
        doneBtnText: "完成", // Text on the final button
        closeBtnText: "关闭", // Text on the close button for this step
        nextBtnText: "下一步", // Next button text for this step
        prevBtnText: "上一步" // Previous button text for this step
        // Called when moving to next step on any step
      })

