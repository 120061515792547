<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-24 18:06:38
 * @LastEditors: Lius
 * @LastEditTime: 2024-07-29 18:59:12
-->
<template>
  <div id="app">
    <!-- <nav>
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </nav> -->
    <router-view/>
  </div>
</template>

<script>
import { getAppGrayScale } from '@/apis/app'
export default {
  name: 'lius_appllication',
  created(){
    this.appGrayScale()
  },
  methods:{
    /** 获取页面灰阶参数 */
    appGrayScale(){
      if(process.env.NODE_ENV==='development')  return
      return;
      let localGray = JSON.parse(localStorage.getItem('appGrayScaleLocal'))
      localGray? this.hangleGrayScale(localGray) : ''
      getAppGrayScale().then(res=>{
        console.log('res gray:',res)
        if(res.data.code != 200)  return false
        let data = res.data.data
        localStorage.setItem('appGrayScaleLocal',JSON.stringify(data))
        this.hangleGrayScale(data)
      })
    },
    /** 页面灰阶处理 */
    hangleGrayScale(data){
        if(!data.grayFlag)  return false
        let htmlDom = document.getElementsByTagName('html')
        let grayNum = data.grayValue || 1
        Array.from(htmlDom)[0].style.filter = `grayscale(${grayNum})`
    },
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
// html {
//   filter: grayscale(1);
// }

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

*{
  padding: 0;
  margin: 0;
}
html,body{
  padding: 0;
  margin: 0;
}

</style>
