/*
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-30 21:01:46
 * @LastEditors: Lius
 * @LastEditTime: 2022-07-30 21:17:00
 */
import axios from 'axios'
import store from '@/store'
import errorCode from '@/utils/errorCode'

// 创建 axios 实例对象
const service = axios.create({
  timeout: 20000,
  headers: {
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "application/json;charset=utf-8",
      // "Content-Type": "multipart/form-data;charset=UTF-8",
  },
//   baseURL: process.env.VUE_APP_BASE_API,
  baseURL: 'http://159.75.253.247:3300',
  transformRequest: [
      function(data, headers) {
          if (headers["Content-Type"] === "application/json;charset=utf-8") {
              return JSON.stringify(data)
              // return data; 
          } else if (
              headers["Content-Type"] === "multipart/form-data;charset=UTF-8"
          ) {
              return data;
          }
      },
  ],
});



export default service
