<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-24 23:09:55
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-25 19:17:40
-->
<template>
  <div id="switchEffectCpn" v-show="show">
      <section class="closeTips" @click="close"> 按Q关闭 </section>
      <h3>主页特效切换</h3>
      <ul>
          <li> 粒子动画特效 </li>
          <li> 飞鸟动画特效 </li>
          <li> 极客炫酷背景 </li>
      </ul>
  </div>
</template>

<script>
export default {
    data(){
        return{
            show: true,
            activeIndex: 0,
        }
    },
    mounted(){
        document.querySelector('#switchEffectCpn').className = 'init';  
    },
    methods: {
        // 显示初始化组件、关闭
        open(){
            // this.show = !this.show
            let dom = document.getElementById('switchEffectCpn')
            // console.log('dom',dom.className)
            if(dom.className=='moveOpen'){
                document.querySelector('#switchEffectCpn').className = 'moveOut';  
            }else{
                document.querySelector('#switchEffectCpn').className = 'moveOpen';  
            }
        },
        // 关闭
        close(){
            document.querySelector('#switchEffectCpn').className = 'moveOut';  
        }
        
    }
}
</script>

<style lang="scss" scope>
#switchEffectCpn{
    position: absolute;
    min-height: 120px;
    width: 270px;
    border-radius: 0 0 6px 6px;
    background-color: aliceblue;
    top: 0;
    // animation: switch_open 3s linear 1;
}
#switchEffectCpn.init{
    display: none;
}

#switchEffectCpn.moveOpen{
    animation: switch_open 2s linear 1;
    opacity: 1;
    display: block;
}

#switchEffectCpn.moveOut{
    animation: switch_out 1s linear 1;
    opacity: 0;
}

@keyframes switch_open {
    0%{
        opacity: 0;
        display: block;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes switch_out {
    0%{
        opacity: 0.9;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        display: none;
        transform: scale(0);
    }
}

#switchEffectCpn .closeTips{
    position: absolute;
    top: 3px;
    right: 6px;
    font-size: 14px;
    color: #888;
    cursor: pointer;
}
#switchEffectCpn ul li{
    cursor: pointer;
    list-style: none;
    &:hover{
        background-color: skyblue;
        color: white;
    }
}


</style>