<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-24 18:06:38
 * @LastEditors: Lius
 * @LastEditTime: 2022-12-12 22:47:26
-->
<template>
  <div class="home" id="HomeViews" @keydown="keysUp">
    <section id="home-tips" style="height:1px;width:1px;margin-left:20px"></section>

    <ParticleEffect></ParticleEffect>

    <HomeMenuCpn ref="homeMenuCpn"></HomeMenuCpn>

    <switchEffectCpn id="switchEffectCpn" ref="switchEffectCpn"></switchEffectCpn>

    <weatherCpn id="homeWeatherCpn" ref="homeWeatherCpn"></weatherCpn>
    
    <footerBarCpn ref="footerBarCpn"></footerBarCpn>

  </div>
</template>

<script>
// @ is an alias to /src
import HomeMenuCpn from '@/components/homeMenu/homeMenu.vue'
import ParticleEffect from '@/components/ParticleEffect/ParticleEffect.vue'
import switchEffectCpn from './homePage/switchEffect.vue'
import weatherCpn from '@/components/weatherComponent/weather.vue'
import footerBarCpn from '@/components/footBarComponent/footBarComponent.vue'

export default {
  name: 'HomeView',
  components: {
    ParticleEffect,
    switchEffectCpn,
    HomeMenuCpn,
    weatherCpn,
    footerBarCpn,
  },
  data(){
    return{
      effextIndex: 0,
      isshow: true,
    }
  },
  mounted(){
    this.init()
    // this.$lius_confirm('你确定要删除内容吗你确定要删除内容吗你确定要删除内容吗?','提示',{
    //     type: 'warning',
    //     button1: '取消',
    //     button2: '确定'
    //   }).then((ok) => {
    //     console.log('点击了确定：',ok)
    //   }).catch((err) => {
    //     console.log(err)
    //   })

  },
  methods: {
    init(){
      document.addEventListener('keyup', this.keysUp)
      let steps = [
        {
        element: "#home-tips",
        className: 'HomeViewDriver',
        popover: {
          title: "欢迎来到LiusWq的个人空间主页",
          description: "按下M键显示/隐藏菜单,按Z关闭所有小窗口(A显示)",
          position: "center"
        },
        padding: 1
      }]
      if(!localStorage.getItem('HomeViewDriver')){
        this.$Driver.defineSteps(steps)
        this.$Driver.start()
        localStorage.setItem('HomeViewDriver',new Date())
      }
    },
    // 监听键盘按下事件
    keysUp(e){
      console.log('up:',e.key)
      let key = e.key.toUpperCase()
      // 更换特效选项
      switch (key) {
        case 'Q':{
          console.log('用户按下了Q')
          this.$refs.switchEffectCpn.open()
          break;
        }
        case 'M':{
          this.$refs.homeMenuCpn.init()
          break;
        }
        case 'A': {   //显示所有
          this.$refs.footerBarCpn.open()
          this.$refs.homeWeatherCpn.open()
          this.$refs.switchEffectCpn.open()
          this.$refs.homeMenuCpn.open()
          break;
        }
        case 'Z': {   //关闭所有
          this.$refs.footerBarCpn.close()
          this.$refs.homeWeatherCpn.close()
          this.$refs.switchEffectCpn.close()
          this.$refs.homeMenuCpn.close()
          
          break;
        }
        case 'F': {
          this.$refs.footerBarCpn.close()
        }
        default:
          console.log('无操作按键:',e.key)
          break;
      }
      // if(e.key=='q'||e.key=='Q'){
      //   console.log('用户按下了Q')
      //   this.$refs.switchEffectCpn.open()
      // }else if(e.key=='m'||e.key=='M'){
      //   this.$refs.homeMenuCpn.init()
      // }else if(e.key=='z'||e.key=='Z'){   //关闭全部
      //   this.$refs.footerBarCpn.close()
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.slideFade-enter {
    animation: slideFade-dialog-fade-in 0.5s ease;
}
  .slideFade-leave {
    animation: slideFade-dialog-fade-out 0.5s ease forwards;
  }
  .slideFade-enter-active {
    animation: slideFade-dialog-fade-in 0.5s ease;
  }
  .slideFade-leave-active {
    animation: slideFade-dialog-fade-out 0.5s ease forwards;
  }

#homeWeatherCpn{
  width: 360px;
  position: fixed;
  bottom: 16px;
  left: -20px;
  transform: scale(0.85);
}
  
  @keyframes slideFade-dialog-fade-in {
    0% {
      transform: translate3d(-100%, 0, 0);//修改这个可以控制，上下左右动画，例如：100%为从右到左
      opacity: 1;
    }
    100% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
  @keyframes slideFade-dialog-fade-out {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    100% {
      transform: translate3d(-100%, 0, 0);
      opacity: 1;
    }
  }
</style>
