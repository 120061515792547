<!--
 * @Descripttion: lius-Dialog UI组件
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-09 23:12:48
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-26 10:42:37
-->
<template>
  <div v-if="show" id="liusDialog" :key="key" class="lius-dialog-mask" @click.self="closeDone">
      <section class="lius-dialog" :style="{width,borderRadius}" @click.stop="()=>false">
        <header class="lius-dialog--header">
            <h4 class="lius-dialog--title"> {{ title }} </h4>
            <div class="lius-dialog--header_rightOP">
                <lius-icon v-show="!fullScreenFlag" icon="icon-fullscreen" class="lius-dialog--close" @click="liusDialog_To_FullScreen" />
                <lius-icon v-show="fullScreenFlag" icon="icon-exitfullscreen" class="lius-dialog--close" @click="ExitFullScreen" />
                <div class="lius-dialog--close" @click.stop="close"> x </div> 
            </div>
        </header>
        <section class="lius-dialog--content">  
            <!-- // 因为有可能插槽会有一些样式，这里用span多包一层,并且判断有插槽再创建span -->
            <span v-if='this.$slots.default'>
                <slot></slot>
            </span>
        </section>
        <footer class="liu-dialog--footer" :style="{borderRadius: `0 0 ${borderRadius} ${borderRadius}`}">
            <slot name="footer"></slot>
        </footer>
      </section>
  </div>
</template>

<script>
export default {
    name: 'lius-dialog',    //定义组件名称
    props: {
        /** 弹窗标题 */
        title: {
            type: String,
            default: 'lius-ui弹窗'
        },
        /** 弹窗宽度 */
        width: {
            type: String,
            default: '50%'
        },
        /** 是否显示 */
        visible: {
            type: Boolean,
            default: false
        },
        appendToBody: {
            type: Boolean,
            default: true
        },
        /** 圆角 */
        borderRadius: {
            type: String,
            default: '0px'
        },
        beforeClose: Function,
    },
    data(){
        return{
            show: false,
            key: 0,
            dialogWidth: this.width,
            fullScreenFlag: false,
        }
    },
    watch: {
        visible(val){
            this.show = this.visible || val
            if (val) {
                this.$emit('open');
                // this.$el.addEventListener('scroll', this.updatePopper);
                // this.$nextTick(() => {
                //     this.$refs.dialog.scrollTop = 0;
                // });
                // if (this.appendToBody) {
                //     document.body.appendChild(this.$el);
                // }
            } else {
                // this.$el.removeEventListener('scroll', this.updatePopper);
                if (!this.show) this.$emit('close');
                if (this.destroyOnClose) {
                    this.$nextTick(() => {
                        this.key++;
                    });
                }
            }
        }
    },
    mounted(){
        this.show = this.visible
        document.body.appendChild(this.$el);
        // document.addEventListener('keyup',evt=>{
        //     console.log(evt,this)
        //     if(evt.key == 'Escape'&&this.fullScreenFlag){
        //         this.ExitFullscreen()
        //     }
        // })
    },
    methods: {
        /** 关闭弹窗  */
        close(){
            // props中的值，.sync修饰的数据可以使用 this.$emit('update:属性', newValue) 修改
            this.$emit('update:visible', false);
            this.$emit('close');
            this.show = false
            this.key++;
        },
        /** 点击遮罩层关闭 */
        closeDone(){
            if(typeof this.beforeClose == 'function'){
                this.beforeClose(this.close)
            }else{
                this.close()
            }
        },
        /** 弹窗全屏操作 */
        liusDialog_To_FullScreen(){
            let dialog = document.getElementsByClassName('lius-dialog')[0]
            dialog.classList.add('lius-dialog__fullScreen')  
            this.fullScreenFlag = true
            // dialog.requestFullscreen()
        },
        /** 弹窗退出全屏操作 */
        ExitFullScreen(){
            let dialog = document.getElementsByClassName('lius-dialog')[0]
            dialog.classList.remove('lius-dialog__fullScreen')  
            this.fullScreenFlag = false
            // document.exitFullscreen(); 
        }
    },
    destroyed(){
        // this.$el.parentNode.removeChild(this.$el);
    },

}
</script>

<style>
.lius-dialog-mask{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
    overflow-y: auto;
    z-index: 999;
}
.lius-dialog{
    position: relative;
    width: 640px;
    margin: auto;
    margin-top: 10vh;
    background-color: #fff;
    box-shadow:  0 0 2px #fff;
    border-radius: 6px;
    text-align: left;
}

/* 弹窗全屏 */
.lius-dialog__fullScreen{
    width: 100vw !important;
    height: 100vh;
    top: 0;
    margin: 0;
    border-radius: 0 !important;
}


.lius-dialog--header{
    line-height: 44px;
    border-bottom: 1px solid #ccc;
    padding-left: 10px;
    /* display: flex; */
}

.lius-dialog--title{
    letter-spacing: 1px;
    flex: 1;
}


.lius-dialog--header_rightOP{
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 20px;
    line-height: 20px;
    display: flex;
}
.lius-dialog--close{
    cursor: pointer;
    padding: 0 6px;
}

/* dialog内容区 slot */
.lius-dialog--content{
    padding: 10px 10px 30px 10px;
}


/* dialog底部确认按钮 */
.liu-dialog--footer{
    width: 100%;
    position: sticky;
    bottom: 0px;
    border-top: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    text-align: right;
    background-color: #FFF;
    border-radius: 6px;
}
.liu-dialog--footer button:nth-child(1n){
    margin-right: 20px;
}
.liu-dialog--footer button:nth-last-child(1){
    margin-right: 0px;
}

/* dialog底部确认按钮 */
.lius-dialog__fullScreen .liu-dialog--footer{
    width: 100%;
    position: fixed;
    bottom: 0px;
    border-top: 1px solid #ccc;
    padding: 10px;
    box-sizing: border-box;
    text-align: right;
    background-color: #FFF;
}

</style>