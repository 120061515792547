/*
 * @Descripttion: global.js  一些全局的方法
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-23 19:38:44
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-25 17:49:54
 */

exports.install = function(Vue, optuins) {
    // 获取当前窗口的可视高度
    Vue.prototype.getClientHeight = function(params) {
        var clientHeight=0;
        if(document.body.clientHeight&&document.documentElement.clientHeight){
            var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
        }else{
            var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
        }
        return clientHeight;
    }

    /** 把内容保存为本地文件 */
    Vue.prototype.localSave =  function(content, fileName) {
        let downLink = document.createElement('a')
        downLink.download = fileName
        //字符内容转换为blod地址
        let blob = new Blob([content])
        downLink.href = URL.createObjectURL(blob)
        // 链接插入到页面
        document.body.appendChild(downLink)
        downLink.click()
        // 移除下载链接
        document.body.removeChild(downLink)
    }


}

