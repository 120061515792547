<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-24 15:57:55
 * @LastEditors: Lius
 * @LastEditTime: 2022-07-30 22:40:10
-->
<template>
  <div class="ParticleEffectCpn">
    <vue-particles
      class="canvas-bg"
      color="#ffde00"
      :particleOpacity="0.9"
      :particlesNumber="100"
      shapeType="star"
      :particleSize="7"
      linesColor="#8DD1FE"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.7"
      :linesDistance="150"
      :moveSpeed="5"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
      >
    </vue-particles>
  </div>
</template>

<script>
import 'vue-particles'
/**
color: String类型。默认’#dedede’。粒子颜色。
particleOpacity: Number类型。默认0.7。粒子透明度。
particlesNumber: Number类型。默认80。粒子数量。
shapeType: String类型。默认’circle’。可用的粒子外观类型有：“circle”,“edge”,“triangle”, “polygon”,“star”。
particleSize: Number类型。默认80。单个粒子大小。
linesColor: String类型。默认’#dedede’。线条颜色。
linesWidth: Number类型。默认1。线条宽度。
lineLinked: 布尔类型。默认true。连接线是否可用。
lineOpacity: Number类型。默认0.4。线条透明度。
linesDistance: Number类型。默认150。线条距离。
moveSpeed: Number类型。默认3。粒子运动速度。
hoverEffect: 布尔类型。默认true。是否有hover特效。
hoverMode: String类型。默认true。可用的hover模式有: “grab”, “repulse”, + “bubble”。
clickEffect: 布尔类型。默认true。是否有click特效。
clickMode: String类型。默认true。可用的click模式有: “push”, “remove”, “repulse”, “bubble”。
 */
export default {
    data(){
      return {
      }
    },
    mounted(){
      console.log('eeeeeee')
      // draw();
    }

}
</script>

<style lang="scss" scope>
.ParticleEffectCpn{
  width: 100vw;
  height: calc(100vh - 5px);
  position: absolute;
  z-index: -1;
  box-sizing: border-box;
  background-color: aliceblue;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  padding: 0;
  // background: linear-gradient(#B7B0E3, #FFD3D6);
  // 如果屎全屏展示
  .canvas-bg{
    width: 100%;
    height: 100%;
    background: linear-gradient(#6e6e6e, #111);
    margin: 0;
    padding: 0;
  }
}

</style>