<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-21 02:11:25
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-21 02:32:58
-->
<template>
    <div class="htmleaf-container">
      <div class="loading">
        <div><span></span></div>
        <div><span></span></div>
        <div><span></span></div>
      </div>
    </div>
</template>
 
<script>
export default {
  name: "lius-loading",
  data() {
    return {};
  },
  props: {},
  components: {},
  methods: {},
  created() {},
  mounted() {
      console.log('loading mouted:',this)
  },
  beforeDestroy() {},
};
</script>
 
<style scoped>
.loading{
    width: 40px;
    height: 40px;
    margin: 0 auto;
    margin-top: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    transform: translateY(-50%);
    animation: load 1.8s linear infinite;
}
.loading div{
    width: 100%;
    height: 100%;
    position: absolute;
}
.loading span{
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #99CC66;
    position: absolute;
    left: 50%;
    margin-top: -10px;
    margin-left: -10px;
    animation: changeBgColor 3s ease infinite;
}
@keyframes load{
    0%{
        transform: rotate(0deg);
    }
    33.3%{
        transform: rotate(120deg);
    }
    66.6%{
        transform: rotate(240deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
@keyframes changeBgColor{
    0%,100%{
        background: #99CC66;
    }
    33.3%{
        background: #FFFF66;
    }
    66.6%{
        background: #FF6666;
    }
}
.loading div:nth-child(2){
    transform: rotate(120deg);
}
.loading div:nth-child(3){
    transform: rotate(240deg);
}
.loading div:nth-child(2) span{
    animation-delay: 1s;
}
.loading div:nth-child(3) span{
    animation-delay: 2s;
}
</style>