<!--
 * @Descripttion:  lius-button
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-14 18:25:41
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-09 22:28:33
-->
<template>
    <button class="lius-button" @click="handleClick" :type="type" 
        :class="[type? 'lius-button--'+type : '',buttonSize ? 'el-button--' + buttonSize : '',]">
        <span v-if="$slots.default"><slot></slot></span>
    </button>
</template>

<script>
export default {
    name: 'liusButton',
    props: {
        // 按钮的type类型值
        type: {
            type: String,
            default: 'default'
        },
        size: String,
        icon: {
            type: String,
            default: ''
        },
        nativeType: {
            type: String,
            default: 'button'
        },
        loading: Boolean,
        disabled: Boolean,
        plain: Boolean,
        autofocus: Boolean,
        round: Boolean,
        circle: Boolean
    },
    computed: {
        buttonSize() {
            return this.size
        }
    },
    methods: {
        handleClick(event){
            this.$emit('click', event)
        },
    }

}
</script>

<style>
.lius-button{
    cursor: pointer;
    padding: 6px 16px;
    letter-spacing: 2.5px;
    border: none;
    border-radius: 4px;
}

/* primary success info warning danger */

.lius-button--primary{
    background-color: #409EFF;
    border: 1px solid #409EFF;
    color: #FFF;
}
.lius-button--primary:hover,.lius-button:hover{
    background-color: #66b1ff;
    border: 1px solid #66b1ff;
    color: #FFF;
}

.lius-button--success{
    background-color: #67c23a;
    border: 1px solid #67c23a;
    color: #FFF;
}
.lius-button--success:hover{
    background-color: #66b1ff;
    border: 1px solid #66b1ff;
}
.lius-button--info{
    background-color: #909399;
    border: 1px solid #909399;
    color: #FFF;
}
.lius-button--warning{
    background-color: #e6a23c;
    border: 1px solid #e6a23c;
    color: #FFF;
}
.lius-button--warning{
    background-color: #e0b26c;
    border: 1px solid #e0b26c;
    color: #FFF;
}
.lius-button--danger{
    background-color: #f56c6c;
    border: 1px solid #f56c6c;
    color: #FFF;
}
.lius-button--danger:hover{
    background-color: #eb9898;
    border: 1px solid #eb9898;
    color: #FFF;
}



</style>