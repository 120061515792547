<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-09-11 15:01:50
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-13 17:46:18
-->
<template>
  <div class="CodePreviewCpn" v-show="show">
      <header class="header"> 
        <i class="lius-icon-double-arrow-right" @click.up="show=false"></i>
        <lius-icon icon="icon-fullscreen" @click="requestScreenFull"></lius-icon>
      </header>
      <div class="previewCodeBox">
         <section v-html="code" v-if="type=='html'"></section>
        <section v-if="typeList.indexOf(type) == -1" class="noSupport"> 不支持预览{{codeType}}代码! </section>
      </div>
  </div>
</template>

<script>
export default {
    name: 'CodePreviewCpn',
    props: {
        value: String,
        codeType: String,
    },
    computed:{
        code() {
            return (this.value)
        },
        type(){
            return this.codeType.toLocaleLowerCase()
        }
    },
    data(){
        return {
            show: true,
            // 支持的语言类型
            typeList: ['html','javascript','vue','node','text']
        }
    },
    mounted(){
        // console.log('CodePreviewCpn:',this.codeType)
    },
    methods: {
        requestScreenFull(){
            let dom = document.querySelector('.previewCodeBox')
            dom.requestFullscreen()
        },
    },
}
</script>

<style lang="scss" scoped>
.CodePreviewCpn{
    // border: 1px solid red;
    min-height: 50px;
    min-width: 240px;
    background-color: aliceblue;
    box-sizing: border-box;
    padding-top: 4px;
    .previewCodeBox{
        background-color: aliceblue;
        padding: 4px 10px;
    }
    .noSupport{
        padding-top: 20px;
        text-align: center;
        color: #777;
        letter-spacing: 2px;
    }
}
</style>>
