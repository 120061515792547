/*
 * @Descripttion: 自定义指令集
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-21 01:53:53
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-25 20:35:09
 */


import Loading from '../packages/liusLoading/index'
import Empty from '../packages/liusEmpty'

const install = (Vue)=>{
    Vue.directive('loading', Loading)
    Vue.directive('empty', Empty)
}

export default {
    install
}