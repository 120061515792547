<!--
 * @Descripttion: 个人主页菜单
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-25 16:05:48
 * @LastEditors: Lius
 * @LastEditTime: 2024-07-29 18:38:11
-->
<template>
  <div id="homeMenuCpn" class="homeMenuCpnClass"  @contextmenu="()=>false">
      <div id="hexahedronBox" @mousemove="hexahedronMousemove" 
        @mousedown="hnMousedown" @mouseup="mouseDownFlag=false" @mouseleave="()=>{}">
        <section :id="'mSection'+(index*1+1)" class="mSectionItem" v-for="(m,index) in list" :key="index"
            @click="toMenuPath(index+1)" @contextmenu="contextmenu"> 
            <div>{{ m.name }}</div> 
        </section>
        <!-- <section id="mSection2" class="mSectionItem" @click="toMenuPath(2)"> <div>开发学习笔记/文档</div>  </section>
        <section id="mSection3" class="mSectionItem" @click="toMenuPath(3)"> <div>项目演示</div> </section>
        <section id="mSection4" class="mSectionItem"> <div>知识宝典</div> </section>
        <section id="mSection5" class="mSectionItem" @click="toMenuPath(5)"> <div>小工具</div> </section>
        <section id="mSection6" class="mSectionItem"> <div>站点信息</div> </section> -->
      </div>
  </div>
</template>

<script>
export default {
    name: 'menuCpn0',
    props: {
        list: {
            type: Array,
            default: () => []
        }
    },
    data(){
        return{
            // 鼠标是否按下的状态
            mouseDownFlag: false,
            firstPageX: 0,
            firstPageY: 0,
        }
    },
    mounted(){
        console.log('加载了菜单0选项')
        this.init()
    },
    methods: {
        // 初始化
        init(){
            document.querySelector('#homeMenuCpn').className = 'init';  
            setTimeout(()=>{
                document.querySelector('#hexahedronBox').className = 'moveIt';  
            },300)
        },
        // 打开
        open(){
            document.querySelector('#homeMenuCpn').classList.add('init');  
            document.querySelector('#homeMenuCpn').classList.remove('moveOut');  
        },
        // 关闭
        close(){
            document.querySelector('#homeMenuCpn').classList.add('moveOut'); 
            document.querySelector('#homeMenuCpn').classList.remove('init');  
        },
        // 鼠标按下 
        hnMousedown(e){
            this.mouseDownFlag = true
            let orginRotateX = document.getElementById('hexahedronBox').style.transform
            document.getElementById('hexahedronBox').style.transform = document.getElementById('hexahedronBox').style.transform
                    console.log('orginRotateX:',orginRotateX)

            document.querySelector('#hexahedronBox').animationFillMode = 'forwards';
            document.querySelector('#hexahedronBox').animation = 'spinY 8s infinite linear';
            console.log('鼠标按下:',e)
            // if(!this.firstPageX==0){
                this.firstPageX = e.pageX
                this.firstPageY = e.pageY
            // }
            // 阻止事件捕获
            e.stopImmediatePropagation();
        },
        // 鼠标在正方体上移动
        hexahedronMousemove(e){
            if(this.mouseDownFlag){
                let moveX = e.pageX - this.firstPageX
                let moveY = e.pageY - this.firstPageY
                if(moveX>10 || moveY>10){
                    let orginRotateX = document.getElementById('hexahedronBox').style.transform
                    console.log('eee:',moveX,moveY,orginRotateX)
                    let hexahedronBox = document.getElementById('hexahedronBox')
                    hexahedronBox.style.transform =  `rotateX(${-moveY/300*90+'deg'}) rotateY(${moveX/300*90+'deg'})`
                }
                
            }
        },
        // 正方体菜单路由跳转
        toMenuPath(num){
            switch(num){
                case 1: {
                    this.$router.push({
                        path: '/my'
                    })
                    break;
                }
                case 2:{
                    this.$router.push({
                        path: '/reNotes'
                    })
                    break;
                }
                case 3:{
                    this.$router.push({
                        path: '/iframe'
                    })
                    break;
                }
                case 5: {
                    this.$router.push({
                        path: '/myTools'
                    })
                    break;
                }
            }
        },
        /**右键菜单 */
        contextmenu(evt){
            console.log('右键菜单',evt)
        },

    }
}
</script>

<style lang="scss" scope>
#homeMenuCpn{
    position: absolute;
    min-height: 500px;
    width: 520px;
    border-radius: 6px;
    // box-shadow: 0 0 1px #f5f5f5;
    // background-color: aliceblue;
    top: 160px;
    right: 50%;
    transform: translateX(50%);
    opacity: 0.9;
    // display: none;
}

#homeMenuCpn.init{
    animation: homeMenuCpn_open 2s linear 1;
    opacity: 1;
    display: block;
}

#homeMenuCpn.moveOut{
    animation: homeMenuCpn_out 2s linear 1;
}



@keyframes homeMenuCpn_open {
    0%{
        opacity: 0;
        display: block;
        // transform: scale(0);
    }
    100%{
        opacity: 1;
        // transform: scale(1);
    }
}

@keyframes homeMenuCpn_out {
    0%{
        opacity: 0.9;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        display: none;
        transform: scale(0);
    }
}


@import url('./motion.css');

</style>