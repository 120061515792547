<!--
 * @Descripttion: foot
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-23 15:51:19
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-23 15:55:04
-->
<template>
  <div class="liusFooterComponent">
      liusFooterComponent
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.liusFooterComponent{
    position: fixed;
    bottom: 0;
    z-index: 888;
    color: yellow;
}
</style>