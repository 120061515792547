<!--
 * @Descripttion: lius-input
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-14 21:23:38
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-09 17:01:41
-->
<template>
  <input class="lius-input" :type="type" :maxlength="maxLength"
    v-model="iptValue"
    @input="returnBackFn"
    :class="[ type? 'lius-input--'+type:'',
    inputSize? 'lius-input--'+inputSize:'',]"
    >
</template>

<script>
export default {
    name: 'lius-input',
    props: {
        value: [String, Number],
        type: {
            type: String,
            default: 'text'
        },
        // mini small medium large
        size: {
            type: String
        },
        maxLength: {
            type: Number,
            default: 99999999999
        },
    },
    data() {
        return{
            // 输入变量值
            iptValue: this.value || ''
        }
    },
    computed: {
        inputSize(){
            return this.size
        }
    },
    watch: {
        //监听value变化（slect控件不是必要，暂时不明）
        value(newValue, oldValue) {
            this.iptValue = newValue || oldValue;
        }
    },
    methods: {
        returnBackFn(val){
             //input是默认双向绑定事件，select控件也可以用input给父组件传递数据
            this.$emit('input', this.iptValue)
        }
    }
}
</script>

<style>
.lius-input{
    width: 100%;
    height: 32px;
    padding: 0 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    display: inline-block;
}
.lius-input:focus{
    outline: 1px solid skyblue;
}

/** size = large */
.lius-input--large{
    height: 52px;
    font-size: 18px;
}

</style>