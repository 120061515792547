/*
 * @Descripttion: icons.js 图标的数据  数据来源 iconfont官网以svg代码复制粘贴过来
 * @Author: Lius
 * @version: v0.0.1
 * @Date: 2022-08-10 23:31:33
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-20 13:36:40
 */
(function (window) {
    // icon 图标的symbol数据列表，最终拼接成svg, 以id查找对应的图标
    var symbolList = [
        + "" +'<symbol id="icon-fullscreen" viewBox="0 0 1024 1024">' + "" + '<path d="M368.896 192H224a32 32 0 0 0-32 32v137.888a32 32 0 0 0 64 0V256h112.896a32 32 0 0 0 0-64zM784.864 192H640a32 32 0 1 0 0 64h112.864v105.888a32 32 0 1 0 64 0V224a32 32 0 0 0-32-32zM368.896 777.92H256V672a32 32 0 1 0-64 0v137.92a32 32 0 0 0 32 32h144.896a32 32 0 1 0 0-64zM784.864 640a32 32 0 0 0-32 32v105.92H640a32 32 0 1 0 0 64h144.864a32 32 0 0 0 32-32V672a32 32 0 0 0-32-32z" p-id="2544"></path><path d="M912 48h-800c-35.296 0-64 28.704-64 64v800c0 35.296 28.704 64 64 64h800c35.296 0 64-28.704 64-64v-800c0-35.296-28.704-64-64-64z m-800 864v-800h800l0.064 800H112z" p-id="2545"></path>' + "" + "</symbol>",
        + "" +'<symbol id="icon-tuichu" viewBox="0 0 1024 1024">'+""+'<path d="M891.215448 714.840276c-24.275862 0-35.133793 19.667862-35.133793 43.926069l-0.088276 58.579862c-6.779586 33.227034-25.811862 46.362483-66.59531 49.716966l-568.125793 0.300138c-35.098483-4.272552-49.558069-17.831724-54.501517-49.469793l0.653241-604.318897c3.742897-32.114759 15.642483-46.962759 46.238897-52.524138l593.672828-0.317793c30.296276 6.249931 44.314483 21.910069 48.904828 54.377931l-0.141241 49.257931c0 24.275862 10.857931 43.926069 35.133793 43.926069 24.240552 0 34.868966-19.667862 34.868966-43.926069l0.300138-93.854897c-2.807172-45.868138-40.448-82.290759-86.987034-82.449655l-658.696828 0.353103C134.144 90.465103 96.926897 128.441379 96.697379 175.457103l0.370759 676.652138c2.065655 45.267862 38.170483 81.37269 83.42069 83.456l649.057103 0.406069 9.533793 0c47.298207 0 85.556966-37.428966 87.463724-84.250483l0.123586-92.936828C926.649379 734.525793 915.456 714.840276 891.215448 714.840276zM706.56 715.105103c11.723034 11.723034 35.169103 15.536552 47.280552 3.425103l165.005241-165.393655c6.426483-6.426483 8.492138-16.384 7.362207-26.07669-0.158897-6.232276-1.959724-12.111448-5.66731-16.807724-1.253517-2.048-2.665931-3.937103-4.272552-5.543724-1.112276-1.094621-2.401103-2.030345-3.707586-2.966069l-158.631724-159.002483c-12.111448-12.111448-36.422621-9.445517-48.145655 2.277517-11.687724 11.723034-14.689103 36.334345-2.577655 48.428138l100.175448 100.404966L542.455172 493.638621c-17.143172 0-39.000276 15.607172-39.000276 35.998897 0 21.53931 21.857103 35.716414 39.000276 35.716414l262.69131 0.211862-102.029241 102.258759C691.005793 679.936 694.81931 703.382069 706.56 715.105103z"  ></path>'+""+"</symbol>",
        + "" +'<symbol id="icon-exitfullscreen" viewBox="0 0 1024 1024">'+""+'<path d="M889 1024H135C60.6 1024 0 963.4 0 889V135C0 60.6 60.6 0 135 0h754c74.4 0 135 60.6 135 135v754c0 74.4-60.6 135-135 135zM135 90c-24.8 0-45 20.2-45 45v754c0 24.8 20.2 45 45 45h754c24.8 0 45-20.2 45-45V135c0-24.8-20.2-45-45-45H135z" p-id="3347"></path><path d="M435 480H219c-24.9 0-45-20.1-45-45s20.1-45 45-45h216c24.9 0 45 20.1 45 45s-20.1 45-45 45z" p-id="3348"></path><path d="M435 480c-24.9 0-45-20.1-45-45V219c0-24.9 20.1-45 45-45s45 20.1 45 45v216c0 24.9-20.1 45-45 45z" p-id="3349"></path><path d="M381.2 425.6c-11.5 0-23-4.4-31.8-13.2L196.6 259.7c-17.6-17.6-17.6-46.1 0-63.6 17.6-17.6 46.1-17.6 63.6 0L413 348.8c17.6 17.6 17.6 46.1 0 63.6-8.8 8.9-20.3 13.2-31.8 13.2z" p-id="3350"></path><path d="M805 634H589c-24.9 0-45-20.1-45-45s20.1-45 45-45h216c24.9 0 45 20.1 45 45s-20.1 45-45 45z" p-id="3351"></path><path d="M589 850c-24.9 0-45-20.1-45-45V589c0-24.9 20.1-45 45-45s45 20.1 45 45v216c0 24.9-20.1 45-45 45z" p-id="3352"></path><path d="M795.5 841.1c-11.5 0-23-4.4-31.8-13.2L611 675.2c-17.6-17.6-17.6-46.1 0-63.6 17.6-17.6 46.1-17.6 63.6 0l152.7 152.7c17.6 17.6 17.6 46.1 0 63.6-8.7 8.8-20.2 13.2-31.8 13.2z" p-id="3353"></path>'+""+"</symbol>",
        + "" +'<symbol id="icon-dropdown" viewBox="0 0 1024 1024">'+""+'<path d="M787.2 380.8c-9.6-9.6-22.4-12.8-35.2-12.8l-480 3.2c-12.8 0-25.6 3.2-35.2 12.8-19.2 19.2-19.2 48 0 67.2l240 240c0 0 0 0 0 0 0 0 0 0 0 0 3.2 3.2 9.6 6.4 12.8 9.6 0 0 3.2 3.2 3.2 3.2 16 6.4 38.4 3.2 51.2-9.6l240-243.2C806.4 428.8 803.2 400 787.2 380.8z" p-id="2372" fill="#707070"></path>'+""+"</symbol>",
        + "" +'<symbol id="icon-dropdown-white" viewBox="0 0 1024 1024">'+""+'<path d="M79.123059 327.850933l405.024593 413.260162c15.690354 16.009625 41.469484 16.009625 57.160861 0l405.02357-413.260162c24.819269-25.323758 6.877641-68.028373-28.579919-68.028373L107.704001 259.82256C72.245418 259.82256 54.30379 302.527175 79.123059 327.850933z" p-id="2719" fill="#ffffff"></path>'+""+"</symbol>",
       
        

        
    ]
    // icon 图标的symbol数据
    // var svgSprite = "<svg>" + "" + 
    //     '<symbol id="icon-fullscreen" viewBox="0 0 1024 1024">' + "" + '<path d="M368.896 192H224a32 32 0 0 0-32 32v137.888a32 32 0 0 0 64 0V256h112.896a32 32 0 0 0 0-64zM784.864 192H640a32 32 0 1 0 0 64h112.864v105.888a32 32 0 1 0 64 0V224a32 32 0 0 0-32-32zM368.896 777.92H256V672a32 32 0 1 0-64 0v137.92a32 32 0 0 0 32 32h144.896a32 32 0 1 0 0-64zM784.864 640a32 32 0 0 0-32 32v105.92H640a32 32 0 1 0 0 64h144.864a32 32 0 0 0 32-32V672a32 32 0 0 0-32-32z" p-id="2544"></path><path d="M912 48h-800c-35.296 0-64 28.704-64 64v800c0 35.296 28.704 64 64 64h800c35.296 0 64-28.704 64-64v-800c0-35.296-28.704-64-64-64z m-800 864v-800h800l0.064 800H112z" p-id="2545"></path>' + "" + "</symbol>"
    //     + "" +'<symbol id="icon-tuichu" viewBox="0 0 1024 1024">'+""+'<path d="M891.215448 714.840276c-24.275862 0-35.133793 19.667862-35.133793 43.926069l-0.088276 58.579862c-6.779586 33.227034-25.811862 46.362483-66.59531 49.716966l-568.125793 0.300138c-35.098483-4.272552-49.558069-17.831724-54.501517-49.469793l0.653241-604.318897c3.742897-32.114759 15.642483-46.962759 46.238897-52.524138l593.672828-0.317793c30.296276 6.249931 44.314483 21.910069 48.904828 54.377931l-0.141241 49.257931c0 24.275862 10.857931 43.926069 35.133793 43.926069 24.240552 0 34.868966-19.667862 34.868966-43.926069l0.300138-93.854897c-2.807172-45.868138-40.448-82.290759-86.987034-82.449655l-658.696828 0.353103C134.144 90.465103 96.926897 128.441379 96.697379 175.457103l0.370759 676.652138c2.065655 45.267862 38.170483 81.37269 83.42069 83.456l649.057103 0.406069 9.533793 0c47.298207 0 85.556966-37.428966 87.463724-84.250483l0.123586-92.936828C926.649379 734.525793 915.456 714.840276 891.215448 714.840276zM706.56 715.105103c11.723034 11.723034 35.169103 15.536552 47.280552 3.425103l165.005241-165.393655c6.426483-6.426483 8.492138-16.384 7.362207-26.07669-0.158897-6.232276-1.959724-12.111448-5.66731-16.807724-1.253517-2.048-2.665931-3.937103-4.272552-5.543724-1.112276-1.094621-2.401103-2.030345-3.707586-2.966069l-158.631724-159.002483c-12.111448-12.111448-36.422621-9.445517-48.145655 2.277517-11.687724 11.723034-14.689103 36.334345-2.577655 48.428138l100.175448 100.404966L542.455172 493.638621c-17.143172 0-39.000276 15.607172-39.000276 35.998897 0 21.53931 21.857103 35.716414 39.000276 35.716414l262.69131 0.211862-102.029241 102.258759C691.005793 679.936 694.81931 703.382069 706.56 715.105103z"  ></path>'+""+"</symbol>"
    //     + "" + "</svg>";

    // icon 图标的symbol数据
    var svgSprite = "<svg>" + "" +  symbolList.join('')  + "" + "</svg>";



    // js操作
    var script = function () { 
        var scripts = document.getElementsByTagName("script"); 
        return scripts[scripts.length - 1] 
    }(); 
    var shouldInjectCss = script.getAttribute("data-injectcss"); 
    var ready = function (fn) { 
        if (document.addEventListener) { 
            if (~["complete", "loaded", "interactive"].indexOf(document.readyState)) { 
                setTimeout(fn, 0) 
            } else { 
                var loadFn = function () { 
                    document.removeEventListener("DOMContentLoaded", loadFn, false); 
                    fn() 
                }; 
                document.addEventListener("DOMContentLoaded", loadFn, false) 
            } 
        } else if (document.attachEvent) { 
            IEContentLoaded(window, fn) 
        } 
        function IEContentLoaded(w, fn) { 
            var d = w.document, done = false, init = function () { if (!done) { done = true; fn() } }; 
            var polling = function () { 
                try { d.documentElement.doScroll("left") 
            } catch (e) { 
                setTimeout(polling, 50); 
                return 
            } 
            init() 
        }; 
        polling(); 
        d.onreadystatechange = function () { 
            if (d.readyState == "complete") { 
                d.onreadystatechange = null; 
                init() 
            } 
        } 
    } 
}; 
var before = function (el, target) { 
    target.parentNode.insertBefore(el, target) 
}; 
var prepend = function (el, target) { 
    if (target.firstChild) { 
        before(el, target.firstChild) 
    } else { 
        target.appendChild(el) 
    } 
}; 
function appendSvg() { 
    var div, svg; div = document.createElement("div"); 
    div.innerHTML = svgSprite; 
    svgSprite = null; 
    svg = div.getElementsByTagName("svg")[0]; 
    if (svg) { 
        svg.setAttribute("aria-hidden", "true"); 
        svg.style.position = "absolute"; 
        svg.style.width = 0; 
        svg.style.height = 0; 
        svg.style.overflow = "hidden"; 
        prepend(svg, document.body) 
    } 
} 
if (shouldInjectCss && !window.__iconfont__svg__cssinject__) { 
    window.__iconfont__svg__cssinject__ = true; 
    try { 
        document.write("<style>.svgfont {display: inline-block;width: 1em;height: 1em;fill: currentColor;vertical-align: -0.1em;font-size:16px;}</style>") 
    } catch (e) { 
        console && console.log(e) 
    } 
} 

ready(appendSvg)

})(window)





