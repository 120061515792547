<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-09-11 13:32:20
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-13 17:49:15
-->
<template>
  <div class="ace-container">

    <!-- 代码编辑区 -->
    <section class="code-block">
      <div class="ace-editor" ref="ace"></div>
    </section>
 
    
    <!-- 切换蒙版提示按钮 -->
    <div class="bookmarklet" @click="toggleConfigPanel"></div>

    <!-- 蒙版内容 -->
    <div class="config-panel" v-show="toggle">
      <div>
        <div class="item">
          <label class="title">语言</label>
          <select class="value" v-model="modePath" @change="handleModelPathChange" size="mini" value-key="name">
            <option v-for="mode in modeArray"
                       :key="mode.name"
                       :label="mode.name"
                       :value="mode.path">
            </option>
          </select>
        </div>
 
        <div class="item">
          <label class="title">换行</label>
          <select class="value" v-model="wrap" @change="handleWrapChange" size="mini" value-key="name">
            <option v-for="wrap in wrapArray"
                       :key="wrap.name"
                       :label="wrap.name"
                       :value="wrap.value">
            </option>
          </select>
        </div>
      </div>
    </div>

    
  </div>
</template>
 
<script>
  import ace from 'ace-builds'
  import 'ace-builds/src-noconflict/snippets/javascript'
  import 'ace-builds/src-noconflict/snippets/html'
  import 'ace-builds/src-noconflict/snippets/css'
  import 'ace-builds/src-noconflict/snippets/scss'
  import 'ace-builds/src-noconflict/snippets/json'
  import 'ace-builds/src-noconflict/snippets/java'
  import 'ace-builds/src-noconflict/snippets/text'
  import 'ace-builds/webpack-resolver'
  import 'ace-builds/src-noconflict/ext-language_tools'
  import 'ace-builds/src-noconflict/theme-monokai'
  import 'ace-builds/src-noconflict/mode-javascript'
 
  // const themeArray = [{
  //   name: 'monokai',
  //   path: 'ace/theme/monokai'
  // }]
 
  const wrapArray = [{
    name: '开启',
    value: true
  }, {
    name: '关闭',
    value: false
  }]
 
  const modeArray = [{
    name: 'JavaScript',
    path: 'ace/mode/javascript'
  }, {
    name: 'HTML',
    path: 'ace/mode/html'
  }, {
    name: 'CSS',
    path: 'ace/mode/css'
  }, {
    name: 'SCSS',
    path: 'ace/mode/scss'
  }, {
    name: 'Json',
    path: 'ace/mode/json'
  }, {
    name: 'Java',
    path: 'ace/mode/java'
  }, {
    name: 'Text',
    path: 'ace/mode/text'
  }]
 
  export default {
    props: {
      value: String,
      codeType: String,
      // 代码区最小行数
      minLines: {
        type: Number,
        default: 36
      },
    },
    computed: {
      type(){
        return this.codeType.toLocaleLowerCase()
      }
    },
    mounted () {
     this.initEditor()
    },
    data () {
      return {
        aceEditor: null,
        toggle: false,
        wrap: true,
        themePath: 'ace/theme/monokai',
        modePath: 'ace/mode/javascript',
        modeArray: modeArray,
        wrapArray: wrapArray,
      }
    },
    methods: {
      /** 初始化编辑器 */
      initEditor(){
        this.aceEditor = ace.edit(this.$refs.ace, {
            maxLines: 99999999,
            minLines: this.minLines,
            fontSize: 18,
            value: this.value ? this.value : '',
            theme: this.themePath,
            mode: this.modePath,
            wrap: this.wrap,
            tabSize: 4
        })
        // 激活自动提示
        this.aceEditor.setOptions({
            enableSnippets: true,
            enableLiveAutocompletion: true,
            enableBasicAutocompletion: true
        })
        this.aceEditor.getSession().on('change', this.change)
      },
      toggleConfigPanel () {
        this.toggle = !this.toggle
      },
      change () {
        this.$emit('input', this.aceEditor.getSession().getValue())
      },
      handleModelPathChange (modelPath) {
          console.log('modelPath:',modelPath)
        this.aceEditor.getSession().setMode(modeArray[0])
      },
      handleWrapChange (wrap) {
        this.aceEditor.getSession().setUseWrapMode(wrap)
      }
    }
  }
</script>
 
<style lang='scss' scoped>
  .ace-container {
    position: relative;
    .code-block{
      height: calc(100vh - 30px);
      overflow: auto;
    }
 
    .config-panel {
      position: absolute;
      right: 16px;
      bottom: 0;
      width: 20%;
      min-width: 50px;
      height: 100%;
      padding-right: 16px;
      overflow: auto;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 1;
      .item {
        margin: 10px auto;
        text-align: left;
        .title {
          color: white;
          margin: 0 10px;
          font-size: 14px;
        }
      }
    }
 
    .bookmarklet {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 16px;
      height: 16px;
      z-index: 2;
      cursor: pointer;
      border-width: 9px;
      border-style: solid;
      border-color: lightblue gray gray rgb(206, 173, 230);
      border-image: initial;
    }
  }
</style>
