import { render, staticRenderFns } from "./lius-empty.vue?vue&type=template&id=29e69e80&"
import script from "./lius-empty.vue?vue&type=script&lang=js&"
export * from "./lius-empty.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports