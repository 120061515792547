<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-20 21:42:13
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-24 16:11:09
-->
<template>
  <div class="lius-trree-node">
    <div @click="handleToggle" class="lius-tree-title" :class="{'lius-tree-title-noChildren': !hasChild}" @contextmenu="contextmenu($event,list)">
      <i v-if="hasChild&&this.list.children.length>0" :class="open?'lius-icon-circle-plus-outline--open':'lius-icon-circle-plus-outline'"></i>
      <h4 :style="{paddingLeft: hasChild? '2px':'2px'} ">{{ list.title }} </h4>
    </div>
    <div v-show='open' v-if="hasChild"  class="child-content">
      <lius-tree-node
        v-for="(item, index) in list.children"
        :list="item"
        :key="keys+index"
        @node-click="handleNodeClick"
        @contextmenu="contextmenu($event,item)"
        @node-context-menu="nodeContextMenu"
      ></lius-tree-node>
    </div>
  </div>
</template>

<script>
export default {
    name: 'lius-tree-node',
    props: {
        list: {
            type: Object,
            default: ()=>{}
        },
        defaultProps: {
            type: Object,
        },
        selectedNode: {
           type: Object,
           default: null
        }
    },
    data(){
        return {
            open: false,
        }
    },
    computed: {
        hasChild() {
            // return this.list.children && this.list.children.length;
            return this.list.children;
        },
        keys(){
            return getUuid()
        }
    },
    mounted(){
        // console.log('asas:',this.defaultProps,this.list)
    },
    methods: {
        // 点击标题选中
        handleToggle() {
            if (this.hasChild) {
                this.open = !this.open;
                this.$emit('node-click',this.list)
                // console.log('ele:',this.$el.innerHTML)
            }else if(!this.hasChild){
                this.$emit('node-click',this.list)
                this.treeNodeActive()
            }
        },
        // 激活节点
        treeNodeActive(){
            let active_dom = document.getElementsByClassName('lius-tree-node--active')
            for (let dom of active_dom) {
                dom.classList.remove('lius-tree-node--active')
            }
            this.$el.classList.add('lius-tree-node--active')
        },
        // 点击节点
        handleNodeClick(val){
            this.$emit('node-click',val)
        },
        // 判断是否选中节点
        hasSelectedNode(){
            return (this.selectedNode == this.list && !this.hasChild)
        },
        // 节点右键菜单
        contextmenu(e,val){
            this.$emit('node-context-menu',{event:e,data:val})
            // if(this.hasChild){
            //     this.$emit('node-contextmenu-parent',{event:e,data:val})
            // }else{
            //     this.$emit('node-contextmenu-child',{event:e,data:val})
            // }
        },
        // 右键菜单 子 (传递事件出去)
        nodeContextmenuChild(val){
            this.$emit('node-contextmenu-child',val)
        },
        // 右键菜单
        nodeContextMenu(val){
            this.$emit('node-context-menu',val)
        },
    }

}
</script>

<style>
.lius-trree-node{
    cursor: pointer;
    padding-left: 10px;
}
.lius-tree-title{
    display: flex;
    padding: 4px 10px;
}
.lius-tree-title:hover{
   background-color: #eee;
}
.child-content{
    padding-left: 10px;
}

.lius-icon-remove-outline{
    width: 0;
    height: 0;
    border-top: 6px solid #bbb;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    margin: 8px 2px;
    border-radius: 6px;
}
.lius-icon-circle-plus-outline{
    width: 0;
    height: 0;
    border-left: 6px solid #bbb;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    margin: 5px 2px;
    border-radius: 6px;
}
.lius-icon-circle-plus-outline--open{
    width: 0;
    height: 0;
    border-left: 6px solid #bbb;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    margin: 5px 2px;
    border-radius: 6px;
    animation: icon_0_90deg 0.3s linear;
    animation-fill-mode:forwards;
}

@keyframes icon_0_90deg{
    0%{
        transform: rotateZ(0deg);
    }
    100%{
        transform: rotateZ(90deg);
    }
}

@keyframes icon_90_0deg{
    0%{
        transform: rotateZ(90deg);
    }
    100%{
        transform: rotateZ(0deg);
    }
}


.lius-tree-title-noChildren h4{
    color: #333;
    font-weight: 500;
    font-size: 14px;
}

.lius-tree-node--active h4{
    color: red !important;
}

</style>