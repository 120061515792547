<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-09-09 17:28:12
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-09 22:36:34
-->
<template>
  <transition name="confirm" class="lius-message-box">
    <div class="modal" v-if="visible">
      <div class="confirm" :class="type" >
        <div class="tips"> {{ tips }} </div>
        <div class="content">
          <i class="lius-message_status lius-icon-warning"></i>
          <!-- <img class="Icon lius-message_status lius-icon-warning" v-if="type === 'success'"  src="" alt=""> -->
          <!-- <img class="Icon lius-message_status lius-icon-warning" v-else-if="type === 'warning'"  src="" alt=""> -->
          {{content}}
        </div>
        <div class="buttonArea">
          <lius-button @click="visible=false;close()">{{button1}}</lius-button>
          <lius-button type="danger" @click="visible=false;ok()" style="margin-left: 25px">{{button2}}</lius-button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import liusButton from '../liusButton/lius-button.vue'
export default {
  components: { liusButton },
  name: 'LiusConfirm',
  data () {
    return {
      content: '',
      tips: '提示',
      time: 300000, // 30秒不操作的话提示框自动消失
      visible: false,
      type: 'warning', // 'success','warning','error','info'
      button1: '取消',
      button2: '确定'
    }
  },
  mounted () {
    this.close()
  },
  methods: {
    close () {
      window.setTimeout(() => {
        console.log('关闭消息提示')
        this.visible = false
      }, this.time)
    }
  }
}
</script>
<style scoped>
  @keyframes fadeInDown{
    0%{opacity:0;-webkit-transform:translate3d(0,-1%,0);transform:translate3d(0,-1%,0)}
    to{opacity:1;-webkit-transform:translateZ(0);transform:translateZ(0)}}
  @keyframes fadeOutUp{
    0%{opacity:1}
    to{opacity:0;-webkit-transform:translate3d(0,-1%,0);transform:translate3d(0,-1%,0)}}
  .confirm-enter-active {
    animation: fadeInDown 0.5s;
  }
  .confirm-leave-active {
    animation: fadeOutUp 0.5s;
  }
    .modal{
        z-index: 99;
        background-color: rgba(0,0,0,0.5);
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }
   
    .confirm{
        padding: 10px 20px;
        text-align: left;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        width: fit-content;
        height: auto;
        margin: 0 auto;
        margin-top: 40%;
        transform: translateY(-50%);
        position: relative;
    }
     
    .content{
        margin: 20px 0px;
    }
    .Icon{
        height: 20px;
        padding-right: 8px;
    }
        
    .buttonArea{
        margin-top: 10px;
        text-align: right;
    }
        
          



</style>






