/*
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-24 18:06:38
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-11 15:03:42
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueParticles from 'vue-particles'
Vue.use(VueParticles)

/** 引入并注册markdown */
import  mavonEditor  from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
Vue.use(mavonEditor)



/** 引入自己的UI组件库，并挂载到全局 */
import LiusUI from '../lius-ui/index'
Vue.use(LiusUI)

import axios from 'axios'
Vue.prototype.$axios = axios

import Driver from './utils/dirver/driver'
Vue.prototype.$Driver = Driver

import '../lius-ui/styles/lius.css'


// 全局引入组件
import Editor from '@/components/Editor/index.vue'
import Markdown from '@/components/markdown/markdown'
import footerCpn from '@/components/liusFootComponent/liusFootComponent'
import UserLogin from '@/components/userLogin/userLogin'
import CodeEditor from '@/components/CodeEditor/codeEditor'
import CodePreview from '@/components/CodeEditor/codePreview'

// 全局挂载组件
Vue.component('Editor', Editor)
Vue.component('Markdown', Markdown)
Vue.component('FooterCpn', footerCpn)
Vue.component('UserLogin', UserLogin)
Vue.component('CodeEditor', CodeEditor)
Vue.component('CodePreview', CodePreview)


import GlobalJS from '@/utils/global'
Vue.use(GlobalJS)

/** ace在线代码编辑器插件 */
import ace from 'ace-builds'
Vue.use(ace)




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
