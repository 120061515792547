
/* istanbul ignore next */
// ElDialog.install = function(Vue) {
//     Vue.component(ElDialog.name, ElDialog);
//   };
//   export default ElDialog;



/*
 * @Descripttion:  所有组件的入口 index.js  整合所有lius-ui组件
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-09 23:10:55
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-09 23:14:33
 */

// 所有组件的入口，我们可以在这里进行扩展一些组件，并进行整合
import liusButton from './packages/liusButton/lius-button.vue'
import liusIcon from './packages/lius-icon/liusIcon.vue'
import liusInput from './packages/liusInput/lius-input.vue'

import LiusDialog from './packages/lius-dialog/liusDialog.vue'
import LiusTree from './packages/liusTree/lius-tree.vue'

import notifyMessage from './packages/liusMessage/liusMessage'


// 引入
import { messageControl } from './packages/liusMessage//message.js'
// 初始化绑定
const notifyCompoent = new messageControl()

// 自定义指令
import v_directive from './utils/vue-directive'

import lius_message_box from './packages/liusMessageBox/lius-message-box'


// 在install方法里注册 全局组件
// 引入的时候，use这个方法
const install = (Vue) => {
  // 使用name获取到定义好的名字，方便修改
  Vue.component(liusButton.name, liusButton)
  Vue.component(liusIcon.name, liusIcon)
  Vue.component(liusInput.name, liusInput)
  
  Vue.component(LiusDialog.name, LiusDialog)
  Vue.component(LiusTree.name, LiusTree)

  Vue.use(notifyMessage.register)
  Vue.prototype.$liusMessage = notifyCompoent.message.bind(notifyCompoent)

  // 自定义指令
  Vue.use(v_directive)
  
  Vue.use(lius_message_box)
  


}


// 如果是script标签的方式引入并不会调用install方法，这里需要处理一下
// 当前全局window下有Vue实例的话，直接调用install把Vue传进去
if (typeof window.Vue !== 'undefined') {
  install(Vue)
}
// Vue只有用script标签的方式导入才会挂载到window上
// import的方式导入是挂载不到window上的，而是在当前的模块内


import { getUuid } from './utils/uuid'
import Vue from 'vue'
window.getUuid = getUuid


export default {
  install
}

