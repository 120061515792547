<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-23 18:37:52
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-30 21:33:48
-->

<template>
    <div class="lius-vue-markdown" v-loading="loading">
        <!-- <mavon-editor v-model="push.articleBody" ref="md" :height="900" @imgAdd="imgAdd" @imgDel="imgDel" /> -->
        <MarkdownPro v-model="currentValue" :height="height" :toolbars="toolbars" :exportFileName="mdTitle" autoSave  @on-upload-image="imageUpload"></MarkdownPro>
    </div>
</template>

<script>
// import axios from 'axios'

import { MarkdownPro } from 'vue-meditor'
import { MarkdownPreview } from 'vue-meditor'
export default {
    name: 'markdown',
    props: {
        /* 编辑器的内容 */
        value: {
            type: String,
            default: "",
        },
        /* 高度 */
        height: {
            type: Number,
            default: 600,
        },
        /* 最小高度 */
        minHeight: {
            type: Number,
            default: null,
        },
        /* 只读 */
        readOnly: {
            type: Boolean,
            default: false,
        },
        // 上传文件大小限制(MB)
        fileSize: {
            type: Number,
            default: 5,
        },
        // 文章标题
        mdTitle: {
            type: String,
            default: "unnamed",
        }
    },
    data(){
        return {
            loading: false,
            // marldown内容
            currentValue: '',
            toolbars: {
                uploadImage: true,
                save: false,
                clear: false,
                print: true,
                h4: true,
                h5: true,
                h6: true,
            }
        }
    },
    components: {
        MarkdownPro,
        MarkdownPreview,
    },
    watch: {
        value: {
            handler(val) {
                if (val !== this.currentValue) {
                    this.currentValue = val === null ? "" : val;
                }
            },
            immediate: true,
        },
        currentValue: {
            handler(val) {
                // console.log('currentValue:',val)
                this.$emit('update:value',val)
            },
            immediate: true,
        },
    },
    methods : {
        /** 图片上传 */
        imageUpload(file){
            this.loading = true
            console.log('file::',file)
            // /tencentCOS/upload
            let formData = new FormData();
            formData.append('file', file)   //注意，这里必须上传文件的name为avatar要和服务端接收的保持一致
            this.$axios.post('http://159.75.253.247:3300/tencentCOS/upload', formData)
            .then(res=>{
                console.log('上传成功！！！', res)
                this.currentValue += ` \n![image](${res.data.data.url})\n`
                this.loading = false
            }).catch(err=>{
                console.log('上传失败::',err)
            })
            // http({
            //     url: '/tencentCOS/upload',
            //     method: 'post',
            //     headers: {
            //         'data-type': 'Buffer',
            //         'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            //     },
            // })
        }
    }
}
</script>

<style scoped>
.lius-vue-markdown {
    width: 100%;
    height: 100%;
    margin: auto;
    text-align: left;
    /* outline: 1px solid red; */
}
</style>











