import { render, staticRenderFns } from "./liusFootComponent.vue?vue&type=template&id=37258f88&scoped=true&"
import script from "./liusFootComponent.vue?vue&type=script&lang=js&"
export * from "./liusFootComponent.vue?vue&type=script&lang=js&"
import style0 from "./liusFootComponent.vue?vue&type=style&index=0&id=37258f88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37258f88",
  null
  
)

export default component.exports