<!--
 * @Descripttion: userLogin 用户登录
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-30 21:58:24
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-09 17:20:52
-->
<template>
  <div class="userLogin hidden-login" v-show="show">
      <section class="login-box">
            <section class="login-header">
                <img class="icon" src="@/assets/logo.png" alt="" srcset="">
                <h3> 登 录 </h3>
            </section>
            <form action="" class="login-form">
                <div class="login-form-item">
                    <label for="" class="label"> 用户： </label>
                    <lius-input v-model="form.user" type="text" />
                </div>
                <div style="margin-top: 40px" class="login-form-item">
                    <label for="" class="label"> 密码：</label>
                    <lius-input v-model="form.password" type="password" /> 
                </div>

            </form>
            <section class="opeator-btn">
                <lius-button class="btn-submit" type="primary" @click="submit"> 登录 </lius-button>
                <lius-button class="btn-submit" type="primary"> 取消 </lius-button>
            </section>
      </section>
      
      <section class="userLogin-mask"> </section>
  </div>
</template>

<script>
import { login, } from '@/apis/login'
export default {
    name: 'userLogin',
    data(){
        return {
            show: false,
            form: {
                user: '',
                password: '',
            },
        }
    },
    created(){
        document.addEventListener('keydown',(event)=>{
            // 不是documemnt层dom的监听(属于冒泡出来的事件)
            if(event.path.length>=5) return;
            // console.log('login-keyup:',event)
            if(event.key.toUpperCase() == 'L'){
                this.show ? this.closeLogin() : this.openLogin()
            }
        })
    },
    methods: {
        /** 提交登录 */
        submit(){
            if(!this.checkForm())   return
            let data = {
                user: this.form.user,
                password: this.form.password
            }
            login(data).then(res=>{
                console.log('登录结果：',res)
                if(res.data.code!=200){
                    this.$liusMessage({ content: res.data.msg,type: 'error', noNotifyBtn: false, time: 1500 })
                }else{
                    localStorage.setItem('lius-token',res.data.token)
                    // this.$router.go(0)
                }
            })
        },
        checkForm(){
            let errMsg = []
            if(!this.form.user){
                errMsg.push('请输入账号')
            }
            if(!this.form.password){
                errMsg.push('请输入登录密码')
            }
            if(errMsg.length>0){
                this.$liusMessage({ content: errMsg.join(','),type: 'error', noNotifyBtn: false, time: 1500 })
                return false
            }
            return true
        },
        openLogin(){
            this.show = true
            let login_dom = document.querySelector('.userLogin')
            login_dom.classList.add('show-login')
        },
        closeLogin(){
            let login_dom = document.querySelector('.userLogin')
            login_dom.classList.remove('show-login')
            this.show = false
        },
    }
}
</script>

<style lang="scss" scope>
.userLogin{
    width: 100vw;
    height: 100vh;
    margin: 0;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    text-align: left;
}
.userLogin-mask{
    width: 100vw;
    height: 100vh;
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.7;
}

.hidden-login{
    opacity: 0;
}
.show-login{
    display: flex;
    animation: login-show 2s linear;
    animation-fill-mode:forwards;
}
.close-login{
    animation: login-hidden 0.1s linear;
    animation-fill-mode:forwards;
}

.login-box{
    width: 450px;
    height: 320px;
    background-color: #FFF;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 0px 6px #FFF;
    padding: 10px 40px;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.login-form-item{
    display: flex;
    .label{
        min-width: 60px;
        display: block;
        letter-spacing: 1.6px;
    }
}



@keyframes login-show {
    0%{

    }
    40%{
        opacity: 0.7;
    }
    100%{
        opacity: 1;
    }
}

@keyframes login-hidden {
    0%{

    }
    60%{
        opacity: 0.5;
    }
    100%{
        opacity: 0;
        display: none;
    }
}

.opeator-btn{
    padding: 20px;
    .btn-submit{
        margin-right: 20px;
        margin-bottom: 10px;
        border-radius: 6px;
    }
}

.login-header{
    display: flex;
    justify-content: center;
    line-height: 40px;
    letter-spacing: 3px;
    margin-bottom: 20px;
    img{
        width: 40px;
        border-radius: 50%;
        margin-right: 20px;
    }
}

</style>