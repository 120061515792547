/*
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-24 18:06:38
 * @LastEditors: Lius
 * @LastEditTime: 2022-12-12 22:43:36
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import myView from '@/views/myPage/index.vue'

Vue.use(VueRouter)


/**
 * 各个模块路由数组集
 */
// 我的
let myRoutes = [{
    path: '/my',
    name: 'my',
    component: myView
  },
]

// 文档
let reNotesRoutes = [ 
  {
    path: '/reNotes',
    name: 'reNotes',
    component: () => import( '@/views/researchNotes/index.vue'),
    children: []
  },
  // 发布文章页面
  {
    path: '/pushCircle',
    name: 'pushCircle',
    component: () => import( '@/views/researchNotes/pushCircle/pushCircle.vue'),
  },
]

// 小工具
let myToolsRoutes = [
  {
    path: '/myTools',
    name: 'myTools',
    component: () => import( '@/views/myTools/index.vue'),
  },
  {
    path: '/myTools/codeEditor',
    name: 'CodeEditor',
    component: () => import( '@/views/myTools/codeEditorPage/codeEditor.vue'),
  },
  {
    path: '/myTools/myDepComponents',
    name: 'MyDepComponents',
    component: () => import( '@/views/myTools/myDepComponents/components.vue'),
  },
  {
    path: '/myTools/drillGround',
    name: 'DrillGround',
    component: () => import( '@/views/myTools/drillGround/index.vue'),
  },
  
  
]

// 路由
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/mainLogin.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401.vue'),
    hidden: true
  },
   // 所有未定义路由，全部重定向到404页
   {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/redirect',
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
      }
    ]
  },
  // iframe
  {
    path: '/iframe',
    name: 'iframe',
    component: () => import( '@/views/Iframe/index.vue')
  },{
    path: '/siteInformation',
    name: 'SiteInformation',
    component: () => import( '@/views/siteInformation/siteInfo.vue')
  },
  
  ...myRoutes,
  ...reNotesRoutes,
  ...myToolsRoutes,
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


// 全局路由守卫
router.beforeEach((to, from, next) => {
  console.log('navigation-guards');
  // to: Route: 即将要进入的目标 路由对象
  // from: Route: 当前导航正要离开的路由
  // next: Function: 一定要调用该方法来 resolve 这个钩子。执行效果依赖 next 方法的调用参数。

  // const nextRoute = ['home', 'good-list', 'good-detail', 'cart', 'profile'];
  // let isLogin = global.isLogin;  // 是否登录
  // // 未登录状态；当路由到nextRoute指定页时，跳转至login
  // if (nextRoute.indexOf(to.name) >= 0) {  
  //   if (!isLogin) {
  //     console.log('what fuck');
  //     router.push({ name: 'login' })
  //   }
  // }
  // // 已登录状态；当路由到login时，跳转至home 
  // if (to.name === 'login') {
  //   if (isLogin) {
  //     router.push({ name: 'home' });
  //   }
  // }
  next();
});


export default router
