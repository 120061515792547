/*
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-09-09 17:29:31
 * @LastEditors: Lius
 * @LastEditTime: 2022-09-09 22:39:54
 */

import Vue from 'vue'
import Confirm from './lius-message-box.vue'

Vue.prototype.$lius_confirm = function (content='',tips='提示',options) {
  return new Promise((resolve, reject) => { // promise封装，ok执行resolve，no执行rejectlet
    if (options === undefined || options === null) {
      options = {
        content: ''
      }
    } else if (typeof options === 'string' || typeof options === 'number') {
      options = {
        content: options
      }
    }
    options.content = content
    options.tips = tips
    var Confirm1 = Vue.extend(Confirm)
    var component = new Confirm1({
      data: options
    }).$mount()
    document.body.appendChild(component.$el)
    component.ok = function () {
        resolve()
    }
    component.close = function () {
        reject()
    //   reject(new Error())
    }
    Vue.nextTick(() => {
      component.visible = true
    })
  })
}
Confirm.install = function (Vue) {
  Vue.component(Confirm.name, Confirm)
}
export default Confirm

