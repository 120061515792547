<!--
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-21 13:43:02
 * @LastEditors: Lius
 * @LastEditTime: 2024-07-29 18:44:44
-->
<template>
	<div class="footBarComponent">
		<div class="footBarComponent-box1">
			<p style="margin-right:10px"> @Liuswq </p>
			<p><a href="http://www.wqlius.top" target="_blank"> www.wqlius.top </a></p>
		</div>
		<p style="cursor: default;"> {{ classicQuotation() }} </p>
		<div class="footBarComponent-box2">
			<p> <a href="https://beian.miit.gov.cn" target="_blank"> <p> 蜀ICP备2021022650号-1 </p> </a> </p>
			<p> <a href="http://1.14.59.29" target="_blank"> ipv4: 1.14.59.29 </a> </p>
		</div>
	</div>
</template>

<script>
export default{
	name: 'footerBarCpn',
	data(){
		return{
			classicQList: [{
				text: '路漫漫其修远兮'
			},{
				text: '天道酬勤'
			}]
		}
	},
	mounted(){
        document.querySelector('.footBarComponent').classList.add('init');  
	},
	methods: {
		// 打开
		open(){
        	document.querySelector('.footBarComponent').classList.add('open');  
        	document.querySelector('.footBarComponent').classList.remove('close');  
		},
		// 关闭
		close(){
        	document.querySelector('.footBarComponent').classList.add('close'); 
        	document.querySelector('.footBarComponent').classList.remove('open');  
		},
		// 
		classicQuotation(){
			let randNum = Math.floor(Math.random()*this.classicQList.length)
			return this.classicQList[randNum].text
		},
	},
}
</script>

<style lang="scss" scoped="scoped">
	.footBarComponent{
		width: 100vw;
		min-height: 24px;
		color: white;
		line-height: 16px;
		padding: 8px 10px 12px 10px;
		box-sizing: border-box;
		border-top: 1px solid #333;
		position: fixed;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-wrap: wrap;
		background-color: rgba(0,0,0, .9);
		font-size: 14px;
		transition: all .3s;

		&:hover {
			min-height: 64px;
		}

		p{
			flex: 1;
			cursor: pointer;
		}
		p:hover{
			font-size: 17px;
			color: greenyellow;
			transition: all .5s;
		}
	}
	a{
		text-decoration: none;
		color: greenyellow;
		&:active{
			text-decoration: none;
			color: greenyellow;
		}
	}
	.footBarComponent-box1{
		display: flex;
	}
	.footBarComponent-box2{
		flex: 1;
		display: flex;
	}
	

.init{
    display: flex;
}

.open{
    animation: switch_open 2s linear 1;
    opacity: 1;
}

.close{
    animation: switch_out 1s linear 1;
    opacity: 0;
}

@keyframes switch_open {
    0%{
        opacity: 0;
        transform: scale(0);
    }
    100%{
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes switch_out {
    0%{
        opacity: 0.9;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        transform: scale(0);
    }
}

</style>
