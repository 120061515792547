<!--
 * @Descripttion: empty
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-25 18:55:29
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-25 18:57:33
-->

<template>
  <div class="lius-empty">
      lius-empty
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>