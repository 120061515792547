import { render, staticRenderFns } from "./footBarComponent.vue?vue&type=template&id=710fbd13&scoped=true&"
import script from "./footBarComponent.vue?vue&type=script&lang=js&"
export * from "./footBarComponent.vue?vue&type=script&lang=js&"
import style0 from "./footBarComponent.vue?vue&type=style&index=0&id=710fbd13&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "710fbd13",
  null
  
)

export default component.exports