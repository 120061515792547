/*
 * @Descripttion: 
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-30 23:24:49
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-30 23:25:47
 */
import request from '@/request'

//   登录
export function login(data) {
  return request({
      url: '/login',
      method: 'post',
      data: data
  })
}