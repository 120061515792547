<!--
 * @Descripttion: 个人主页菜单
 * @Author: Lius
 * @version: 
 * @Date: 2022-07-25 16:05:48
 * @LastEditors: Lius
 * @LastEditTime: 2022-12-06 00:00:13
-->
<template>
  <div id="homeMenuPage"  @contextmenu="()=>false">
      <MenuCpn0 :list="menuList" v-if="menuCpnIndex==0" ></MenuCpn0>
      <MenuCpn1 :list="menuList" v-if="menuCpnIndex==1" @toPath="toMenuPath"></MenuCpn1>
      <MenuCpn2 :list="menuList" v-if="menuCpnIndex==2" @toPath="toMenuPath"></MenuCpn2>
      
      <section class="doubleArrowBox">
          <div class="singleArrowLeft" @click="menuStep(-1)"></div>
          <div class="singleArrowRight" @click="menuStep(1)"></div>
      </section>
  </div>
</template>

<script>
import MenuCpn0 from './menuCpn0.vue'
import MenuCpn1 from './menuCpn1.vue'
import MenuCpn2 from './menuCpn2.vue'
export default {
    name: 'home',
    components: {
        MenuCpn0,
        MenuCpn1,
        MenuCpn2,
    },
    data(){
        return{
            menuList: [{
                path: '',
                name: '我的',
            },{
                path: '',
                name: '开发学习笔记/文档',
            },{
                path: '',
                name: '项目演示',
            },{
                path: '',
                name: '知识宝典',
            },{
                path: '',
                name: '小工具',
            },{
                path: '',
                name: '站点信息',
            }],
            menuCpnIndex: 1,
            // 鼠标是否按下的状态
            mouseDownFlag: false,
            firstPageX: 0,
            firstPageY: 0,
        }
    },
    created(){
        this.menuCpnIndex = Math.floor(Math.random()*3)
        // document.querySelector('#homeMenuCpn').className = 'init';  
        // setTimeout(()=>{
        //     document.querySelector('#hexahedronBox').className = 'moveIt';  
        // },300)
    },
    methods: {
        // 初始化
        init(){
            // document.querySelector('#homeMenuCpn').className = 'init';  
            let dom = document.getElementById('homeMenuCpn')
            if(dom.className=='init'){
                document.querySelector('#homeMenuCpn').className = 'moveOut';  
            }else{
                document.querySelector('#homeMenuCpn').className = 'init';  
            }
        },
        // 步进
        menuStep(step){
            this.menuCpnIndex += step
            this.menuCpnIndex > 2 ? this.menuCpnIndex = 0 : ''
            this.menuCpnIndex < 0 ? this.menuCpnIndex = 2 : ''
        },
        // 打开
        open(){
            document.querySelector('#homeMenuCpn').classList.add('init');  
            document.querySelector('#homeMenuCpn').classList.remove('moveOut');  
        },
        // 关闭
        close(){
            document.querySelector('#homeMenuCpn').classList.add('moveOut'); 
            document.querySelector('#homeMenuCpn').classList.remove('init');  
        },
        // 鼠标按下 
        hnMousedown(e){
            this.mouseDownFlag = true
            // document.querySelector('#hexahedronBox').className = '';  
            let orginRotateX = document.getElementById('hexahedronBox').style.transform
            document.getElementById('hexahedronBox').style.transform = document.getElementById('hexahedronBox').style.transform
                    console.log('orginRotateX:',orginRotateX)

            document.querySelector('#hexahedronBox').animationFillMode = 'forwards';
            document.querySelector('#hexahedronBox').animation = 'spinY 8s infinite linear';
            console.log('鼠标按下:',e)
            // if(!this.firstPageX==0){
                this.firstPageX = e.pageX
                this.firstPageY = e.pageY
            // }
            // 阻止事件捕获
            e.stopImmediatePropagation();
        },
        // 鼠标在正方体上移动
        hexahedronMousemove(e){
            if(this.mouseDownFlag){
                let moveX = e.pageX - this.firstPageX
                let moveY = e.pageY - this.firstPageY
                if(moveX>10 || moveY>10){
                    let orginRotateX = document.getElementById('hexahedronBox').style.transform
                    console.log('eee:',moveX,moveY,orginRotateX)
                    let hexahedronBox = document.getElementById('hexahedronBox')
                    hexahedronBox.style.transform =  `rotateX(${-moveY/300*90+'deg'}) rotateY(${moveX/300*90+'deg'})`
                }
                
            }
        },
        // 正方体菜单路由跳转
        toMenuPath(num){
            console.log('num:',num)
            switch(num){
                case 1: {
                    this.$router.push({
                        path: '/my'
                    })
                    break;
                }
                case 2:{
                    this.$router.push({
                        path: '/reNotes'
                    })
                    break;
                }
                case 3:{
                    this.$router.push({
                        path: '/iframe'
                    })
                    break;
                }
                case 5: {
                    this.$router.push({
                        path: '/myTools'
                    })
                    break;
                }
                case 6: {
                    this.$router.push({
                        path: '/siteInformation'
                    })
                    break;
                }
            }
        },
        /**右键菜单 */
        contextmenu(evt){
            console.log('右键菜单',evt)
        },

    }
}
</script>

<style lang="scss" scope>

#homeMenuPage{
    .doubleArrowBox{
        position: fixed;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        z-index: 9999;
    }
    .singleArrowLeft,.singleArrowRight{
		width: 30px;
		height: 10px;
		position: relative;
		background-color: #15ff00;
        cursor: pointer;
        opacity: 0.6;
        animation: 1s;
        &:hover{
            opacity: 1;
            box-shadow: 0 0 4px #ccc;
        }
	}
    .singleArrowRight {
        margin-left: 20px;
	}
	.singleArrowRight::after {
		content: '';
		display: block;
		position: absolute;
		right: -20px;  /* 箭头位置 */
		top: -5px;  /* 箭头位置 */
		border-top: 10px solid transparent; 	/* 箭头高低 */
		border-bottom: 10px solid transparent; /* 箭头高低 */
		border-left: 20px solid #00b9f7; /* 箭头长度*/
	}
    .singleArrowLeft::before {
		content: '';
		display: block;
		position: absolute;
		left: -20px;  /* 箭头位置 */
		top: -5px;  /* 箭头位置 */
		border-top: 10px solid transparent; 	/* 箭头高低 */
		border-bottom: 10px solid transparent; /* 箭头高低 */
		border-left: 20px solid #00b9f7; /* 箭头长度*/
        transform: rotateZ(180deg);
	}
	
}

</style>