<!--
 * @Descripttion: tree
 * @Author: Lius
 * @version: 
 * @Date: 2022-08-20 21:12:13
 * @LastEditors: Lius
 * @LastEditTime: 2022-08-24 14:54:30
-->


<template>
  <div class="lius-tree">
      <section v-for="(tree,index) in data" :key="keys+index"> 
          <lius-tree-node :list="tree" :defaultProps="nodeProps" :selectedNode="selectedNode" 
            @node-click="handleNodeClick" @node-contextmenu-parent="nodeContextmenuParent"
            @node-contextmenu-child="nodeContextmenuChild"
            @node-context-menu="nodeContextMenu"></lius-tree-node>
      </section>
  </div>
</template>

<script>
import liusTreeNode from './lius-tree-node.vue'
export default {
    name: 'lius-tree',
    props: {
        data: {
            type: Array,
            default: ()=>[]
        },
        defaultProps: {
            type: Object,
            default: ()=>{
                return{
                    label: 'label',
                    children: 'children'
                }
            } 
        }
    },
    components: {
        liusTreeNode,
    },
    data() {
        return {
            datas: [{
                label: '树形菜单',
                children: [{
                    label: 'treeex',
                    children: [{
                        label: 'treeex',
                        children: []
                    }]
                }]
            },{
                label: 'tree2',
                children: [{
                    label: 'treeey',
                    children: []
                }]
            }],
            selectedNode: null,
        }
    }, 
    computed:{
        keys(){
            return getUuid()
        },
        nodeProps() {
            return {
                label: this.defaultProps.label,
                children: this.defaultProps.children
            }
        }
    },
    mounted(){
        // console.log(getUuid(),this.data)
        // console.log('asas:',this.nodeProps)
    },
    methods: {
        // 点击节点
        handleNodeClick(val){
            this.$emit('node-click',val)
            this.selectedNode = val
            this.$forceUpdate()
        },
        // 右键菜单 父
        nodeContextmenuParent(val){
            this.$emit('node-contextmenu-parent',val)
        },
        // 右键菜单 子
        nodeContextmenuChild(val){
            console.log('treeVal:',val)
            this.$emit('node-contextmenu-child',val)
        },
        // 右键菜单
        nodeContextMenu(val){
            this.$emit('node-context-menu',val)
        },
    },
}
</script>

<style>
.lius-tree{
    text-align: left;
    padding: 6px;
}

</style>